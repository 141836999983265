import React, { useEffect } from "react";

const AdCashAntiAdblock = () => {
  const fetchLibrary = async () => {
    try {
      const response = await fetch("https://us-central1-dissonant-druid.cloudfunctions.net/getAdCashLibrary");
      const libraryCode = await response.text();

      // Inject the cleaned JavaScript into the DOM
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = libraryCode;
      document.head.appendChild(script);

      // Initialize AdCash AutoTag
      script.onload = () => {
        window.aclib && window.aclib.runAutoTag({
          zoneId: "emtvoq8s5y", // Replace with your AdCash Zone ID
        });
      };
    } catch (error) {
      console.error("Failed to load AdCash library:", error.message);
    }
  };

  useEffect(() => {
    fetchLibrary();
  }, []);

  return null; // No UI elements required for this component
};

export default AdCashAntiAdblock;
