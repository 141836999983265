import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import '../QuizData.css'; // Ensure the path is correct
import OutstreamVideoAd from './OutstreamVideoAd';
import InterstitialAd from './InterstitialAd';
import { Banner } from 'exoclick-react';
import AdCashBanner from './AdCashBanner';

// Helper function to shuffle options
const shuffleArray = (array) => {
    return array
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
};

const Quiz = ({ quizData }) => {
    const navigate = useNavigate();
    const { questionIndex } = useParams();
    const currentQuestionIndex = parseInt(questionIndex, 10) || 0;

    const currentQuestion = quizData.data
        ? quizData.data[currentQuestionIndex]
        : quizData[currentQuestionIndex];

    const [selectedOption, setSelectedOption] = useState(null);
    const [correctAnswersCount, setCorrectAnswersCount] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [shuffledOptions, setShuffledOptions] = useState([]);
    const [showAd, setShowAd] = useState(false)

    useEffect(() => {
        if (currentQuestion) {
            setShuffledOptions(shuffleArray([...currentQuestion.options]));
        }
    }, [currentQuestionIndex, currentQuestion]);

    // Scroll to the top of the page when the results are shown
    useEffect(() => {
        if (showResult) {
            window.scrollTo(0, 0);
        }
    }, [showResult]);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    const handleNext = () => {
        if (selectedOption === currentQuestion.correctAnswer) {
            setCorrectAnswersCount(correctAnswersCount + 1);
        }
    
        if (currentQuestionIndex < quizData.data.length - 1) {
            // Use quizPath without a leading slash and construct the path with a single slash
            navigate(`/quiz/${quizData.quizPath}/${currentQuestionIndex + 1}`);
        } else {
            // Show ad before displaying results
            setShowAd(true);
            setShowResult(true)
        }
        setSelectedOption(null);
    };


    const handleShare = async () => {
        // Construct the start URL dynamically based on the quiz title
        const quizTitle = quizData.quizTitle.toLowerCase().replace(/\s+/g, ''); // Remove spaces and make it lowercase if needed
        const quizStartUrl = `${window.location.origin}/quiz/${quizTitle}/0`; // Construct the URL to the beginning of the quiz
    
        const shareData = {
            title: `Quiz Result - ${quizData.quizHeader}`,
            text: `I answered ${correctAnswersCount} out of ${quizData.data.length} questions correctly in the ${quizData.quizTitle} quiz! Try it out and see how you do!`,
            url: quizStartUrl, // Link to the beginning of the quiz
        };
    
        // Assuming `quizData.image` is a URL to the image
        if (navigator.canShare && navigator.canShare({ files: [] }) && quizData.image) {
            try {
                // Fetch the image as a blob
                const response = await fetch(quizData.image);
                const blob = await response.blob();
                const file = new File([blob], 'quiz-image.jpg', { type: blob.type });
    
                // Add the image to the share data
                shareData.files = [file];
            } catch (error) {
                console.error('Error fetching or preparing the image:', error);
            }
        }
    
        if (navigator.share) {
            navigator.share(shareData)
                .then(() => console.log('Share successful'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            // Fallback for devices that don't support Web Share API
            alert(`I answered ${correctAnswersCount} out of ${quizData.data.length} questions correctly in the ${quizData.quizHeader} quiz!`);
        }
    };
    
    const handleGamesButton = async () => {
        navigate("/games")
    }

    const handleQuizButton = async () => {
        navigate('/quiz-list')
    }
    

    if (!currentQuestion) {
        return <h2 className="quiz-result">Invalid question index. Please start the quiz from the beginning.</h2>;
    }

    

    if (showResult) {
        return (
            <div className="quiz-result">
                {/* Quiz Results Section */}
                <h2>Quiz Completed!</h2>
                <p>You answered {correctAnswersCount} out of {quizData.data.length} questions correctly.</p>
                <button className="quiz-button" onClick={handleShare}>
                    Share Your Score
                </button>
                <div className="buttons-container">
                    <button className="more-button" onClick={handleQuizButton}>
                        Test your knowledge of gaming with more quizzes!
                    </button>
                    <h1>OR</h1>
                    <button className="more-button" onClick={handleGamesButton}>
                        Check out some of our games!
                    </button>
                </div>
            </div>
        );
    }
    
    

    return (
        <div className="quiz-container">
            <Helmet>
                <title>{quizData.quizHeader} - Quiz App</title>
                <meta property="og:title" content={quizData.quizHeader} />
                <meta property="og:image" content={quizData.image} />
                {/* Additional optional meta tags for Twitter cards or other social media */}
            </Helmet>

            <div>
               {/* <Banner zoneId={5443860}/>
                <Banner zoneId={5443852}/> */}
                <AdCashBanner zoneId="9185546" />
            </div>
            <h2 className="quiz-header-text">{quizData.quizHeader}</h2>
          {/*  <div><Banner zoneId={5443860}/></div> */}
          <AdCashBanner zoneId="9185554" />
            <h3 className="quiz-header">Question {currentQuestionIndex + 1}: {currentQuestion.question}</h3>
            <div className="quiz-options">
                {shuffledOptions.map((option, index) => (
                    <label key={index} className={`quiz-option ${selectedOption === option ? 'selected' : ''}`}>
                        <input
                            type="radio"
                            name="option"
                            value={option}
                            checked={selectedOption === option}
                            onChange={() => handleOptionSelect(option)}
                        />
                        {option}
                    </label>
                ))}
            </div>

            <button className="quiz-button" onClick={handleNext} disabled={!selectedOption}>
                Next
            </button>
           {/* <OutstreamVideoAd />
            <Banner zoneId={5443860}/>
            <div><Banner zoneId={5443852}/></div> */}
            <AdCashBanner zoneId="9185570" />
            <AdCashBanner zoneId="9185546" />
        </div>
        
    );
};

export default Quiz;
