import React, { useEffect, useRef } from "react";

const AdCashBanner = ({ zoneId }) => {
  const adContainerRef = useRef(null);

  useEffect(() => {
    if (window.aclib) {
      // Create a script tag
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = `
        aclib.runBanner({
          zoneId: '${zoneId}'
        });
      `;

      // Append the script to the ad container
      if (adContainerRef.current) {
        adContainerRef.current.appendChild(script);
      } else {
        console.error("Ad container not found!");
      }
    } else {
      console.error("AdCash library is not loaded!");
    }

    return () => {
      // Cleanup: Remove the script and any dynamically added content
      if (adContainerRef.current) {
        adContainerRef.current.innerHTML = "";
      }
    };
  }, [zoneId]);

  return (
    <div
      ref={adContainerRef}
      style={{
        margin: "20px auto", // Center the banner
        backgroundColor: "#f0f0f0", // Optional for visualization
      }}
    />
  );
};

export default AdCashBanner;
